import React from 'react'
import styled from 'styled-components';
import Layout from "../components/layout"
import Seo from "../components/seo"

const SectionShowreelGroup = styled.div`
  color: black;
  background: black;
  height: 100%;
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  align-items: center;
  
`;

const SectionFlag = styled.div`
  color: #ffe701;
  padding: 200px 10px 200px 10px;
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  
`;



const SockGroup = styled.div`
  margin: 0;
  width: 100%;
  height: auto;
  display: grid;
  margin-top: 0;
  padding-top: 0;
  grid-template-columns: 1fr 1fr 1fr;
  overflow:hidden;

  grid-gap: 1em;


  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);

  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(0, 1fr);

  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  
  }
`;









export default function Showreelpage() {
  return (
    <Layout>
      <Seo title="showreel" />
    <div className="Hero">
      
      <div className="HeroGroup">
        
        <h2>Showreel 2021</h2>
        
        <SectionShowreelGroup>
        
          <SectionFlag>
            <i class="bi bi-play-btn-fill" ></i>
          </SectionFlag>
        </SectionShowreelGroup>
        <p>Video</p>
        <SockGroup>
        <iframe src="https://player.vimeo.com/video/609021452" width="100%" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <iframe src="https://player.vimeo.com/video/559778667" width="100%" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <iframe src="https://player.vimeo.com/video/435161554" width="100%" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <iframe src="https://player.vimeo.com/video/514258142" width="100%" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </SockGroup>
      </div>
    </div>
    </Layout>
  );
}

